import i18n from '../../managers/i18n'

i18n.register(
  'zh-CN',
  {
    ratingLabel: ' 评分：{value} 星，共 {max} 星'
  },
  {
    ns: 'rating'
  }
)
