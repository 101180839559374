var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {}, _obj[_vm.$c('field')] = true, _obj[_vm.$c('invalid')] = _vm.invalid, _obj[_vm.$c('field-abstract')] = _vm.realAbstract, _obj[_vm.$c('field-no-label')] = !_vm.realAbstract && !_vm.label && !_vm.$slots.label, _obj[_vm.$c('field-required')] = _vm.isRequired, _obj[_vm.$c(("field-label-" + _vm.labelPosition))] = true, _obj[_vm.$c(("field-help-" + _vm.helpPosition))] = true, _obj ),attrs:{"ui":_vm.realUi}},[(!_vm.abstract && (_vm.label || _vm.$slots.label))?_c('div',{class:_vm.$c('field-label')},[_vm._t("label",function(){return [(_vm.isRequired)?_c('veui-icon',{class:_vm.$c('field-required-symbol'),attrs:{"name":_vm.icons.required}}):_vm._e(),_c('veui-label',[_vm._v(_vm._s(_vm.label))])]}),(_vm.tip || _vm.$slots.tip)?_c('div',{class:_vm.$c('field-tip')},[_c('veui-icon',{ref:"tip",attrs:{"name":_vm.icons.tip}}),_c('veui-popover',{attrs:{"ui":_vm.uiParts.tip,"target":"tip","aim-center":"","position":"top-start"}},[_vm._t("tip",function(){return [_vm._v(_vm._s(_vm.tip))]})],2)],1):_vm._e(),(
        (_vm.help || _vm.$scopedSlots.help) &&
          _vm.helpPosition === 'top' &&
          _vm.labelPosition === 'top'
      )?_c('veui-message',{class:_vm.$c(("field-help-content-" + _vm.helpPosition)),attrs:{"ui":_vm.uiParts.message,"status":"aux","display":"simple"}},[_vm._t("help",function(){return [_vm._v(_vm._s(_vm.help))]})],2):_vm._e()],2):_vm._e(),_c('div',{class:_vm.$c('field-main')},[_c('div',{class:_vm.$c('field-content-wrap')},[(
          (_vm.help || _vm.$scopedSlots.help) &&
            _vm.helpPosition !== 'bottom' &&
            (_vm.labelPosition !== 'top' || _vm.helpPosition !== 'top')
        )?_c('veui-message',{class:_vm.$c(("field-help-content-" + _vm.helpPosition)),attrs:{"ui":_vm.uiParts.message,"status":"aux","display":"simple"}},[_vm._t("help",function(){return [_vm._v(_vm._s(_vm.help))]})],2):_vm._e(),_c('div',{class:_vm.$c('field-content')},[_vm._t("default",null,{"listeners":_vm.interactiveListeners,"invalid":_vm.invalid,"validities":_vm.validities,"readonly":_vm.realReadonly,"disabled":_vm.realDisabled})],2)],1),(!_vm.realAbstract)?_c('div',{class:_vm.$c('field-messages')},[(_vm.validating)?_c('veui-loading',{attrs:{"loading":_vm.validating,"ui":_vm.uiParts.message}},[_vm._v(" "+_vm._s(_vm.t('validating'))+" ")]):(_vm.validationStatus !== 'success')?[_vm._l((_vm.renderableValidities),function(validity,index){return [(validity.component)?_c(validity.component,{key:("r" + index),tag:"component",attrs:{"validity":validity}}):_c('veui-message',{key:("m" + index),attrs:{"ui":_vm.uiParts.message,"status":validity.status,"display":_vm.realValidityDisplay}},[_c('span',[_vm._v(_vm._s(validity.message))])])]})]:_vm._e(),((_vm.help || _vm.$scopedSlots.help) && _vm.helpPosition === 'bottom')?_c('veui-message',{class:_vm.$c(("field-help-content-" + _vm.helpPosition)),attrs:{"ui":_vm.uiParts.message,"status":"aux","display":"simple"}},[_vm._t("help",function(){return [_vm._v(_vm._s(_vm.help))]})],2):_vm._e()],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }