var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"veui-transfer-demo"},[_vm._m(0),_c('h2',[_vm._v("点击左侧图标展开收起")]),_c('p',[_vm._v("当前展开："+_vm._s(_vm.expanded1))]),_c('veui-tree',{staticClass:"tree",attrs:{"datasource":_vm.treeDatasource1,"expanded":_vm.expanded1},on:{"update:expanded":function($event){_vm.expanded1=$event}}}),_c('h2',[_vm._v("点击整行展开收起")]),_c('veui-tree',{staticClass:"tree",attrs:{"datasource":_vm.treeDatasource2,"item-click":"toggle"}}),_vm._m(1),_c('veui-filter-panel',{staticClass:"veui-select-panel-demo1",attrs:{"datasource":_vm.treeDatasource1},scopedSlots:_vm._u([{key:"head",fn:function(){return [_vm._v("列表")]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('veui-tree',{staticClass:"tree",attrs:{"datasource":items},scopedSlots:_vm._u([{key:"item-label",fn:function(props){return [_vm._t("tree-item-label",function(){return [_vm._v(_vm._s(props.item.label))]},null,props)]}}],null,true)})]}}])}),_vm._m(2),_c('h2',[_vm._v("自定义项目内容")]),_c('veui-transfer',{attrs:{"datasource":_vm.datasource1,"selected-show-mode":"flat"},scopedSlots:_vm._u([{key:"candidate-item-label",fn:function(ref){
var label = ref.label;
return [_c('em',[_vm._v(_vm._s(label))])]}},{key:"selected-item-label",fn:function(ref){
var label = ref.label;
return [_c('small',[_vm._v(_vm._s(label))])]}}])}),_c('h2',[_vm._v("多级树形结构")]),_vm._v(" mergeChecked： "),_c('veui-radio-group',{staticClass:"check-strategy",attrs:{"items":_vm.strategies},model:{value:(_vm.strategy),callback:function ($$v) {_vm.strategy=$$v},expression:"strategy"}}),_c('veui-transfer',{attrs:{"merge-checked":_vm.strategy === 'include-indeterminate' ? 'keep-all' : _vm.strategy,"include-indeterminate":_vm.strategy === 'include-indeterminate',"datasource":_vm.datasource1},scopedSlots:_vm._u([{key:"candidate-title",fn:function(ref){
var count = ref.count;
return [_vm._v("备选列表（"+_vm._s(count)+"）")]}},{key:"selected-title",fn:function(ref){
var count = ref.count;
return [_vm._v("已选列表（"+_vm._s(count)+"）")]}}]),model:{value:(_vm.selected1),callback:function ($$v) {_vm.selected1=$$v},expression:"selected1"}}),_c('div',[_vm._v("selected: "+_vm._s(_vm.selected1))]),_c('h2',[_vm._v("单级结构")]),_c('p',[_c('veui-transfer',{attrs:{"datasource":_vm.datasource2},model:{value:(_vm.selected2),callback:function ($$v) {_vm.selected2=$$v},expression:"selected2"}})],1),_c('h2',[_vm._v("多级树形结构，右侧扁平")]),_c('p',[_c('veui-transfer',{attrs:{"datasource":_vm.datasource3,"selected-show-mode":"flat","ui":"s"},scopedSlots:_vm._u([{key:"candidate-title",fn:function(ref){
var count = ref.count;
return [_vm._v("备选列表（"+_vm._s(count)+"）")]}},{key:"selected-title",fn:function(ref){
var count = ref.count;
return [_vm._v("已选列表（"+_vm._s(count)+"）")]}}]),model:{value:(_vm.selected3),callback:function ($$v) {_vm.selected3=$$v},expression:"selected3"}})],1),_c('h2',[_vm._v("单级结构，禁用")]),_c('p',[_c('veui-transfer',{attrs:{"datasource":_vm.datasource4,"disabled":""},scopedSlots:_vm._u([{key:"candidate-title",fn:function(){return [_vm._v("备选列表")]},proxy:true},{key:"selected-title",fn:function(ref){
var count = ref.count;
return [_vm._v("已选列表（"+_vm._s(count)+"）")]}}]),model:{value:(_vm.selected4),callback:function ($$v) {_vm.selected4=$$v},expression:"selected4"}})],1),_c('h2',[_vm._v("多级树形结构，禁用")]),_c('p',[_c('veui-transfer',{attrs:{"datasource":_vm.datasource5,"selected-show-mode":"flat","disabled":""},scopedSlots:_vm._u([{key:"candidate-title",fn:function(ref){
var count = ref.count;
return [_vm._v("备选列表（"+_vm._s(count)+"）")]}},{key:"selected-title",fn:function(ref){
var count = ref.count;
return [_vm._v("已选列表（"+_vm._s(count)+"）")]}}]),model:{value:(_vm.selected5),callback:function ($$v) {_vm.selected5=$$v},expression:"selected5"}})],1),_c('h2',[_vm._v("用于表单")]),_c('veui-form',{attrs:{"data":_vm.formData,"validators":_vm.validators}},[_c('veui-field',{attrs:{"label":"咖啡：","field":"selected6","name":"selected6","rules":"required"}},[_c('veui-transfer',{attrs:{"datasource":_vm.datasource6,"candidate-placeholder":"搜索备选列表","selected-placeholder":"搜索已选列表"},scopedSlots:_vm._u([{key:"candidate-title",fn:function(ref){
var count = ref.count;
return [_vm._v("备选列表（"+_vm._s(count)+"）")]}},{key:"selected-title",fn:function(ref){
var count = ref.count;
return [_vm._v("已选列表（"+_vm._s(count)+"）")]}}]),model:{value:(_vm.formData.selected6),callback:function ($$v) {_vm.$set(_vm.formData, "selected6", $$v)},expression:"formData.selected6"}})],1),_c('div',{staticClass:"operation"},[_c('veui-button',{attrs:{"ui":"primary","type":"submit"}},[_vm._v("提交")])],1)],1),_c('h2',[_vm._v("自定义candidate")]),_c('veui-transfer',{attrs:{"datasource":_vm.datasource4},scopedSlots:_vm._u([{key:"candidate",fn:function(ref){
var datasource = ref.datasource;
return [_c('veui-table',{staticClass:"custom-table",attrs:{"data":datasource,"selectable":"","selected":_vm.selected4,"key-field":"value"},on:{"update:selected":function($event){_vm.selected4=$event}}},[_c('veui-table-column',{attrs:{"field":"value","title":"ID"}}),_c('veui-table-column',{attrs:{"field":"label","title":"Name"}})],1)]}}]),model:{value:(_vm.selected4),callback:function ($$v) {_vm.selected4=$$v},expression:"selected4"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',[_c('code',[_vm._v("<veui-tree>")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',[_c('code',[_vm._v("<veui-filter-panel>")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',[_c('code',[_vm._v("<veui-transfer>")])])}]

export { render, staticRenderFns }