<template>
<span :class="$c('span')" v-bind="$attrs" v-on="$listeners">
  <slot/>
</span>
</template>

<script>
import 'veui/locale/zh-Hans/common.js'
import 'veui/locale/en-US/common.js'
import 'focus-visible'

import '../common/global'
import prefix from '../mixins/prefix'

export default {
  name: 'veui-span',
  uiTypes: ['transparent'],
  mixins: [prefix]
}
</script>
