<template>
<article>
  <h1>
    <code>&lt;veui-switch&gt;</code>
  </h1>
  <section>
    <veui-switch v-model="loading" class="switch">加载中</veui-switch>
  </section>
  <section>
    <veui-switch
      v-model="value1"
      class="switch"
      ui="xs"
      :loading="loading"
    >夜间模式</veui-switch>
    <veui-switch v-model="value1" class="switch" ui="xs" readonly/>
    <veui-switch v-model="value1" class="switch" ui="xs" disabled/>
  </section>
  <section>
    <veui-switch
      v-model="value3"
      class="switch"
      ui="s"
      :loading="loading"
    >飞行模式</veui-switch>
    <veui-switch v-model="value3" class="switch" ui="s" readonly/>
    <veui-switch v-model="value3" class="switch" ui="s" disabled/>
  </section>
  <section>
    <veui-switch
      v-model="value2"
      class="switch"
      :loading="loading"
      on-label="开"
      off-label="关"
    >勿扰模式</veui-switch>
    <veui-switch v-model="value2" class="switch" readonly/>
    <veui-switch v-model="value2" class="switch" disabled/>
  </section>
  <section>
    <veui-switch
      class="switch"
      :checked="asyncValue"
      :loading="asyncLoading"
      @click.prevent="toggle"
    >
      异步
      <template #content="{ on }">
        <veui-icon :name="on ? 'check' : 'times'"/>
      </template>
    </veui-switch>
  </section>
</article>
</template>

<script>
import { Switch, Icon } from 'veui'
import 'veui-theme-dls-icons/check'
import 'veui-theme-dls-icons/times'

export default {
  name: 'switch-demo',
  components: {
    'veui-switch': Switch,
    'veui-icon': Icon
  },
  data () {
    return {
      value1: true,
      value2: false,
      value3: true,
      loading: false,
      asyncValue: false,
      asyncLoading: false
    }
  },
  methods: {
    toggle () {
      this.asyncLoading = true
      setTimeout(() => {
        this.asyncValue = !this.asyncValue
        this.asyncLoading = false
      }, 2000)
    }
  }
}
</script>

<style scoped>
section {
  margin-bottom: 10px;
}

.switch {
  margin-right: 10px;
}
</style>
