import { render, staticRenderFns } from "./Tabs.vue?vue&type=template&id=1c0112df&scoped=true&"
import script from "./Tabs.vue?vue&type=script&lang=js&"
export * from "./Tabs.vue?vue&type=script&lang=js&"
import style0 from "./Tabs.vue?vue&type=style&index=0&id=1c0112df&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.8_cache-loader@4.1.0_webpack@4.46.0__css-loader@3.6.0_webpack@4.46.0__lodash@_wvf7hukkyzwi6yp52fm5h5f7ie/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c0112df",
  null
  
)

export default component.exports