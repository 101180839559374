var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$c('pagination'),attrs:{"role":"navigation","aria-label":_vm.t('infoLabel', { page: _vm.page, pageCount: _vm.pageCount }),"ui":_vm.realUi}},[(_vm.showTotal || _vm.showPageSize)?_c('div',{class:_vm.$c('pagination-info')},[(_vm.showTotal)?_c('div',{class:_vm.$c('pagination-total')},[_vm._v(" "+_vm._s(_vm.t('total', { total: _vm.realTotal }))+" ")]):_vm._e(),(_vm.showPageSize)?_c('div',{class:_vm.$c('pagination-size')},[_c('veui-select',{attrs:{"ui":_vm.uiParts.pageSize,"options":_vm.realPageSizes,"overlay-class":_vm.$c('pagination-select-overlay'),"aria-label":_vm.t('pageSizeLabel')},on:{"change":function (size) { return _vm.$emit('pagesizechange', size); }},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.t('pageSize', { size: label }))+" ")]}},{key:"selected",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.t('pageSize', { size: label }))+" ")]}}],null,false,3737369064),model:{value:(_vm.realPageSize),callback:function ($$v) {_vm.realPageSize=$$v},expression:"realPageSize"}})],1):_vm._e()]):_vm._e(),_c('div',{class:_vm.$c('pagination-switch')},[_c('veui-link',{class:_vm.$c('pagination-prev'),attrs:{"tabindex":_vm.to || _vm.page === 1 ? null : '0',"role":_vm.to ? null : 'button',"to":_vm.page === 1 ? '' : _vm.pageNavHref.prev.href,"native":_vm.native,"disabled":_vm.page === 1,"aria-label":_vm.t('prev')},on:{"click":function($event){return _vm.handleRedirect(_vm.pageNavHref.prev.page, $event)}}},[_c('veui-icon',{attrs:{"name":_vm.icons.prev}})],1),_c('ul',{class:[
        _vm.$c('pagination-pages'),
        _vm.$c(("pagination-digit-length-" + _vm.pageDigitLength))
      ]},_vm._l((_vm.pageIndicatorSeries),function(item,i){
      var _obj, _obj$1;
return _c('li',{key:i,class:( _obj = {}, _obj[_vm.$c('pagination-page')] = true, _obj[_vm.$c('active')] = item.current, _obj )},[_c('veui-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: item.forward
              ? _vm.getMoreText('nextPages')
              : _vm.getMoreText('previousPages'),
            disabled: !item.more
          }),expression:"{\n            content: item.forward\n              ? getMoreText('nextPages')\n              : getMoreText('previousPages'),\n            disabled: !item.more\n          }"}],ref:_vm.getKey(item),refInFor:true,class:( _obj$1 = {}, _obj$1[_vm.$c('current')] = item.current, _obj$1[_vm.$c('pagination-more')] = item.more, _obj$1 ),attrs:{"tabindex":"0","disabled":_vm.isEmpty,"role":_vm.to ? null : 'button',"to":item.href,"native":_vm.native,"aria-current":item.current ? 'page' : null,"aria-label":item.current
              ? _vm.t('current', { page: item.page })
              : _vm.t('pageLabel', { page: item.page })},on:{"click":function($event){return _vm.handleRedirect(item, $event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleEnter(item, $event)}}},[(item.more)?[_c('veui-icon',{class:_vm.$c('pagination-more-ellipsis'),attrs:{"name":_vm.icons.more}}),_c('veui-icon',{class:_vm.$c('pagination-more-arrow'),attrs:{"name":item.forward ? _vm.icons.forward : _vm.icons.backward}})]:[_vm._v(_vm._s(item.text))]],2)],1)}),0),_c('veui-link',{class:_vm.$c('pagination-next'),attrs:{"tabindex":_vm.to || _vm.page === _vm.pageCount ? null : '0',"role":_vm.to ? null : 'button',"to":_vm.page === _vm.pageCount ? '' : _vm.pageNavHref.next.href,"native":_vm.native,"disabled":_vm.page === _vm.pageCount || _vm.pageCount === 0,"aria-label":_vm.t('next')},on:{"click":function($event){return _vm.handleRedirect(_vm.pageNavHref.next.page, $event)}}},[_c('veui-icon',{attrs:{"name":_vm.icons.next}})],1)],1),(_vm.realShowGoto)?_c('div',{class:_vm.$c('pagination-goto')},[(_vm.gotoPageLabel[0])?_c('span',{class:_vm.$c('pagination-goto-label-before')},[_vm._v(_vm._s(_vm.gotoPageLabel[0]))]):_vm._e(),_c('veui-input',{on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.gotoPage.apply(null, arguments)}},model:{value:(_vm.targetPage),callback:function ($$v) {_vm.targetPage=$$v},expression:"targetPage"}}),(_vm.gotoPageLabel[1])?_c('span',{class:_vm.$c('pagination-goto-label-after')},[_vm._v(_vm._s(_vm.gotoPageLabel[1]))]):_vm._e(),_c('veui-button',{on:{"click":_vm.gotoPage}},[_vm._v(_vm._s(_vm.t('go')))]),_c('veui-link',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"goto",attrs:{"aria-hidden":"true","to":_vm.realTargetLink},on:{"click":function($event){return _vm.handleRedirect(_vm.pageNumber, $event)}}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }